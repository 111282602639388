import React from "react";

const NextIcon = ({ color }) => {
  return (
    <React.Fragment>
      <svg
        viewBox="0 0 128 128"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M59.813 0.0999956C59.538 0.124993 58.6631 0.212485 57.8757 0.274978C39.715 1.91232 22.7041 11.7114 11.9302 26.7724C5.93078 35.1466 2.09365 44.6456 0.643792 54.7072C0.131342 58.2193 0.0688477 59.2567 0.0688477 64.0187C0.0688477 68.7808 0.131342 69.8182 0.643792 73.3303C4.11845 97.3405 21.2043 117.514 44.377 124.988C48.5266 126.325 52.9012 127.238 57.8757 127.788C59.813 128 68.1872 128 70.1245 127.788C78.7112 126.838 85.9855 124.713 93.1598 121.051C94.2597 120.488 94.4721 120.338 94.3221 120.213C94.2222 120.138 89.5351 113.851 83.9107 106.252L73.6867 92.441L60.8754 73.4803C53.8261 63.0563 48.0267 54.5322 47.9767 54.5322C47.9267 54.5197 47.8767 62.9439 47.8517 73.2304C47.8142 91.2411 47.8017 91.966 47.5767 92.391C47.2517 93.0034 47.0018 93.2534 46.4768 93.5284C46.0769 93.7283 45.7269 93.7658 43.8396 93.7658H41.6773L41.1023 93.4034C40.7274 93.1659 40.4524 92.8534 40.2649 92.491L40.0025 91.9285L40.0274 66.8685L40.0649 41.7959L40.4524 41.3085C40.6524 41.046 41.0773 40.7085 41.3773 40.546C41.8898 40.2961 42.0897 40.2711 44.252 40.2711C46.8018 40.2711 47.2267 40.3711 47.8892 41.096C48.0767 41.296 55.0135 51.7449 63.3127 64.3312C71.6119 76.9175 82.9608 94.1033 88.5352 102.54L98.6592 117.876L99.1717 117.539C103.709 114.589 108.508 110.389 112.308 106.015C120.395 96.7281 125.607 85.4042 127.356 73.3303C127.869 69.8182 127.931 68.7808 127.931 64.0187C127.931 59.2567 127.869 58.2193 127.356 54.7072C123.882 30.697 106.796 10.524 83.6232 3.04971C79.5361 1.72484 75.1865 0.812426 70.312 0.26248C69.1121 0.137492 60.8504 5.36407e-06 59.813 0.0999956V0.0999956ZM85.9855 38.7712C86.5854 39.0712 87.0729 39.6461 87.2478 40.2461C87.3478 40.571 87.3728 47.5204 87.3478 63.1813L87.3103 85.6541L83.3482 79.5797L79.3736 73.5053V57.1694C79.3736 46.6079 79.4236 40.671 79.4986 40.3836C79.6986 39.6836 80.136 39.1337 80.736 38.8087C81.2484 38.5462 81.4359 38.5212 83.3982 38.5212C85.248 38.5212 85.573 38.5462 85.9855 38.7712V38.7712Z" />
        <path d="M98.0341 118.151C97.5966 118.426 97.4592 118.613 97.8466 118.401C98.1216 118.238 98.5716 117.901 98.4966 117.888C98.4591 117.888 98.2466 118.013 98.0341 118.151ZM97.1717 118.713C96.9467 118.888 96.9467 118.901 97.2217 118.763C97.3717 118.688 97.4967 118.601 97.4967 118.576C97.4967 118.476 97.4342 118.501 97.1717 118.713ZM96.5467 119.088C96.3218 119.263 96.3218 119.276 96.5967 119.138C96.7467 119.063 96.8717 118.976 96.8717 118.951C96.8717 118.851 96.8092 118.876 96.5467 119.088ZM95.9218 119.463C95.6968 119.638 95.6968 119.651 95.9718 119.513C96.1218 119.438 96.2468 119.351 96.2468 119.326C96.2468 119.226 96.1843 119.251 95.9218 119.463ZM94.9719 119.963C94.4969 120.213 94.5219 120.313 94.9969 120.076C95.2094 119.963 95.3719 119.851 95.3719 119.826C95.3719 119.738 95.3594 119.751 94.9719 119.963Z" />
      </svg>

      <label className="technology__label">Next.JS</label>
    </React.Fragment>
  );
};

export default NextIcon;
