export const technologies = {
  REACT: 0,
  JAVASCRIPT: 1,
  HTML: 2,
  CSS: 3,
  SASS: 4,
  PARSE: 5,
  GRAPHQL: 6,
  JAVA: 7,
  FIREBASE: 8,
  NEXT: 9,
  MONGODB: 10,
};
