import React from "react";

const MongoDbIcon = ({ color }) => {
  return (
    <React.Fragment>
      <svg
        width="128"
        viewBox="0 0 128 128"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M63.0967 4L66.4838 10.1969C67.2453 11.3404 68.0703 12.3526 69.042 13.2953C71.8909 16.0357 74.5464 18.9605 76.9903 22.0497C82.7295 29.3901 86.6005 37.5418 89.365 46.358C91.0229 51.7436 91.9232 57.2682 91.9906 62.8546C92.2682 79.556 86.3903 93.8968 74.5392 105.815C72.6115 107.705 70.529 109.439 68.3123 111C67.1383 111 66.583 110.123 66.0991 109.316C65.2179 107.818 64.6315 106.174 64.3698 104.467C63.9534 102.446 63.6797 100.426 63.8146 98.3397V97.3971C63.7194 97.1962 62.6842 4.47519 63.0967 4V4Z" />
        <path d="M63.254 3.13477C63.1194 2.86056 62.9848 3.06817 62.8502 3.20136C62.9155 4.57243 62.4464 5.79463 61.7119 6.96199C60.9043 8.12152 59.8352 9.01075 58.7623 9.96658C52.8017 15.2236 48.11 21.5736 44.3529 28.6757C39.3536 38.234 36.7771 48.4778 36.0464 59.2622C35.7119 63.1521 37.254 76.8784 38.4576 80.8388C41.7418 91.3529 47.6409 100.163 55.2821 107.81C57.1587 109.651 59.1661 111.359 61.2427 113C61.8465 113 61.9118 112.452 62.0503 112.044C62.315 111.172 62.5168 110.282 62.654 109.38L64 99.1405L63.254 3.13477Z" />
        <path d="M66.3166 115.688C66.4517 114.128 67.1931 112.833 68 111.543C67.1892 111.203 66.5869 110.529 66.1158 109.776C65.7097 109.063 65.3734 108.312 65.112 107.534C64.1699 104.679 63.9691 101.684 63.6988 98.7666V97C63.3629 97.273 63.2934 99.5817 63.2934 99.9249C63.097 103.008 62.6919 106.074 62.0811 109.101C61.8803 110.326 61.7452 111.546 61 112.635C61 112.771 61 112.908 61.0656 113.11C62.278 116.714 62.61 120.384 62.8147 124.124V125.488C62.8147 127.119 62.749 126.775 64.0888 127.321C64.6293 127.524 65.2317 127.594 65.7722 128C66.1776 128 66.2432 127.661 66.2432 127.388L66.0425 125.145V118.89C65.9768 117.798 66.1776 116.714 66.3127 115.692L66.3166 115.688Z" />
      </svg>

      <label className="technology__label">MongoDB</label>
    </React.Fragment>
  );
};

export default MongoDbIcon;
