import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import Header from "../header/Header";
import Content from "../content/Content";
import Bio from "../bio/Bio";
import Contact from "../contact/Contact";
import Projects from "../projects/Projects";
import SelectedProject from "../selected-project/SelectedProject";
import { projectsData } from "../../data/projects";
import ProjectTransition from "../transitions/ProjectTransition";
import { CSSTransitionGroup } from "react-transition-group";
import Footer from "../footer/Footer";

const Home = withRouter(({ location, history }) => {
  const getProjectsPerDrag = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth < 630) {
      return 1;
    } else if (windowWidth < 760) {
      return 2;
    } else if (windowWidth < 1000) {
      return 2;
    } else {
      return 3;
    }
  };

  const [headerLifted, setHeaderLifted] = useState(false);
  const [projects, setProjects] = useState([...projectsData]);
  const [selectedProject, setSelectedProject] = useState();
  const [projectCardCoords, setProjectCardCoords] = useState();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [projectExpanded, setProjectExpanded] = useState(false);
  const [projectContentReady, setProjectContentReady] = useState(false);
  const [projectActiveWidthChanged, setProjectActiveWidthChanged] =
    useState(false);

  const [projectHover, setProjectHover] = useState(false);

  const [projectsPerDrag, setProjectsPerDrag] = useState(getProjectsPerDrag());
  const [dragStartIndex, setDragStartIndex] = useState(0);
  const [translateXPosition, setTranslateXPosition] = useState(0);

  const [initialLoad, setInitialLoad] = useState(true);

  const directlyLinked = !projectCardCoords;

  const draggerRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);

  let timeout;

  useEffect(() => {
    // If a project has been clicked
    if (location.pathname.includes("project/")) {
      // React to hashes in a normal way
      if (location.hash.includes("#video")) {
        return;
      }

      const projectId = location.pathname.replace("/project/", "");
      const foundProject = projectsData.find((project) => {
        return project.id === projectId;
      });

      if (foundProject) {
        setSelectedProject(foundProject);
      }

      //When project is set

      // If project was not directly linked, show a transition
      if (projectCardCoords) {
        setIsTransitioning(true);
        setTimeout(expandProject, 400);
      } else {
        setProjectExpanded(true);
        setProjectContentReady(true);
      }
      //If 'Projects' was clicked in the Nav bar
    } else if (location.pathname.includes("/projects")) {
      if (directlyLinked) {
        setProjectExpanded(false);
        setProjectContentReady(false);
        setSelectedProject(null);
      }
      scrollToProjects();
      // If 'Contact' was clicked in the nav bar
    } else if (location.pathname.includes("/contact")) {
      if (directlyLinked) {
        setProjectExpanded(false);
        setProjectContentReady(false);
        setSelectedProject(null);
      }
      scrollToContact();
    } else {
      if (selectedProject) {
        if (projectCardCoords) {
          //If width has changed while project was active, don't show any shrink animations
          if (projectActiveWidthChanged) {
            setProjectContentReady(false);
            setProjectExpanded(false);
            setSelectedProject(null);
            setProjectActiveWidthChanged(false);
            setDragStartIndex(0);
          } else {
            resetDragger();
            shrinkProject();
          }
        } else {
          setProjectContentReady(false);
          setProjectExpanded(false);
          setSelectedProject(null);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (initialLoad) {
      hideProjects(dragStartIndex, projectsPerDrag);
      setInitialLoad(false);
    }

    //Hide invisible projects after transition
    let dragTimeout;
    if (!initialLoad) {
      dragTimeout = setTimeout(() => {
        setProjectHover(false);
        hideProjects(dragStartIndex, projectsPerDrag);
      }, 500);
    }

    function handleScroll() {
      if (window.scrollY > 0) {
        setHeaderLifted(true);
      } else {
        setHeaderLifted(false);
      }
    }

    function handleResize() {
      const perDrag = getProjectsPerDrag();

      if (perDrag !== projectsPerDrag) {
        setProjectsPerDrag(perDrag);
        setDragStartIndex(0);

        //Set a flag if width has changed while project info was displayed
        if (selectedProject) {
          setProjectActiveWidthChanged(true);
        }

        if (draggerRef.current) {
          draggerRef.current.style.transform = `translateX(0px)`;
        }
        hideProjects(0, perDrag);
      }
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
      clearTimeout(dragTimeout);
      clearTimeout(timeout);
    };
  }, [dragStartIndex, projectsPerDrag]);

  const scrollToProjects = () => {
    setTimeout(() => {
      projectsRef.current.scrollIntoView();
    }, 10);
  };

  const scrollToContact = () => {
    setTimeout(() => {
      contactRef.current.scrollIntoView();
    }, 10);
  };

  const getProjectWidth = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth < 370) {
      return 242;
    } else if (windowWidth < 630) {
      return 308;
    } else if (windowWidth < 760) {
      return 254;
    } else if (windowWidth < 1000) {
      return 304;
    } else {
      return 279.33;
    }
  };

  const handleNavItemClick = async (page) => {
    if (directlyLinked) {
      scrollToId(page);
    } else {
      if (selectedProject) {
        await resetDragger();
        shrinkProject();
        await scrollToIdWithDelay(400, page);
      } else {
        scrollToId(page);
      }
    }
  };

  const scrollToIdWithDelay = async (delay, page) => {
    await setTimeout(() => scrollToId(page), delay);
  };

  const scrollToId = (page) => {
    history.push(`/${page}`);
  };

  const expandProject = () => {
    setProjectExpanded(true);
    setTimeout(() => {
      // Reveal content
      setIsTransitioning(false);
      setProjectContentReady(true);
      window.scrollTo(0, 0);
    }, 200);
  };

  const shrinkProject = () => {
    setProjectContentReady(false);
    setIsTransitioning(true);
    setTimeout(() => {
      setProjectExpanded(false);
      setTimeout(() => {
        setIsTransitioning(false);
        setSelectedProject(null);
      }, 200);
    }, 200);
  };

  const handleProjectSelect = (params) => {
    history.push(`/project/${projects[params.index].id}`);
    setProjectCardCoords(params);
  };

  const handleBackClick = () => {
    history.push("/");
  };

  const resetDragger = async () => {
    await setTimeout(() => {
      draggerRef.current.style.transform = `translateX(${translateXPosition}px)`;
    }, 10);
  };

  const hideProjects = (startIndex, projectsPerDrag) => {
    const projectsCopy = [...projectsData];

    for (var i = 0; i < startIndex; i++) {
      projectsCopy[i] = {};
    }

    for (var a = startIndex + projectsPerDrag; a < projectsData.length; a++) {
      projectsCopy[a] = {};
    }

    setProjects(projectsCopy);
  };

  const handleProjectForwardClick = () => {
    setProjectHover(true);
    setProjects([...projectsData]);
    // Adjust projects per drag
    let newDragIndex = dragStartIndex + projectsPerDrag;

    if (newDragIndex + projectsPerDrag > projectsData.length) {
      const difference = newDragIndex + projectsPerDrag - projectsData.length;
      newDragIndex = newDragIndex - difference;
    }

    let translateX = newDragIndex * (getProjectWidth() * -1);
    draggerRef.current.style.transform = `translateX(${translateX}px)`;
    setTranslateXPosition(translateX);

    setDragStartIndex(newDragIndex);
  };

  const handleProjectBackClick = () => {
    setProjectHover(true);
    setProjects([...projectsData]);

    // Adjust projects per drag
    let newDragIndex = dragStartIndex - projectsPerDrag;

    if (newDragIndex < 0) {
      newDragIndex = 0;
    }

    let translateX = newDragIndex * (getProjectWidth() * -1);
    draggerRef.current.style.transform = `translateX(${translateX}px)`;
    setTranslateXPosition(translateX);

    setDragStartIndex(newDragIndex);
  };

  return (
    <React.Fragment>
      <Header
        lifted={headerLifted}
        projectExpanded={projectExpanded ? true : false}
        onBackClick={handleBackClick}
        onNavItemClick={handleNavItemClick}
      />

      <CSSTransitionGroup
        transitionName="fadeInFromBottom"
        transitionEnterTimeout={200}
        transitionLeaveTimeout={200}
        transitionAppear={false}
        transitionLeave={!directlyLinked}
        transitionEnter={!directlyLinked}
      >
        {projectContentReady && (
          <SelectedProject project={selectedProject} key="selected-project" />
        )}
      </CSSTransitionGroup>

      {isTransitioning && (
        <ProjectTransition
          windowParams={projectCardCoords}
          projectExpanded={projectExpanded}
        />
      )}
      <CSSTransitionGroup
        transitionName="fadeIn"
        transitionEnterTimeout={200}
        transitionLeaveTimeout={200}
        transitionAppear={true}
        transitionLeave={false}
        transitionEnter={false}
      >
        {!projectContentReady && (
          <React.Fragment>
            <Content>
              <Bio />
              <div className="projects" id="projects" ref={projectsRef}>
                <Projects
                  onSelect={handleProjectSelect}
                  selectedProjectId={selectedProject && selectedProject.id}
                  projects={projects}
                  projectHover={projectHover}
                  onForward={handleProjectForwardClick}
                  onBack={handleProjectBackClick}
                  draggerRef={draggerRef}
                  forwardEnabled={
                    dragStartIndex + projectsPerDrag < projectsData.length
                  }
                  backEnabled={dragStartIndex !== 0}
                  isTransitioning={isTransitioning}
                />
              </div>
              <Contact contactRef={contactRef} />
            </Content>
          </React.Fragment>
        )}
        <Footer />
      </CSSTransitionGroup>
    </React.Fragment>
  );
});

export default Home;
