import React from "react";

const DefaultButton = ({ children, onClick, className, name }) => {
  const handleOnClick = e => {
    onClick(name);
  };

  return (
    <button
      className={`btn ${className ? className : ""}`}
      onClick={handleOnClick}
    >
      {children}
    </button>
  );
};

export default DefaultButton;
