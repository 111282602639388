import React from "react";
import photo from "../../images/profile_pic.jpg";
import SectionHeading from "../section-heading/SectionHeading";

const Bio = () => {
  return (
    <div className="bio">
      <div className="summary">
        <img className="summary__photo" alt="profile" src={photo} />
        <div className="summary-details">
          <h1 className="summary-details__name">Zilvinas Kupcinskas</h1>
          <p className="summary-details__summary">
            Self-taught web and mobile developer with a focus on front end
            technologies.
          </p>
        </div>
      </div>
      <SectionHeading>About me</SectionHeading>
      <p className="bio__about-me">
        I'm a Front End Developer with over 4 years of commercial experience,
        currently working at Papaya. Being self-motivated and adaptable, I'm
        always excited about bringing new ideas to reality and working with the
        latest tools to solve real life problems. In my spare time I love
        playing around with Unreal Engine and Unity, 3D modelling and
        travelling.
      </p>
    </div>
  );
};

export default Bio;
