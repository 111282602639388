import React from "react";

const ArrowLeftIcon = ({ onClick }) => {
  return (
    <div className="arrow-back" onClick={onClick}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="#5A5D7A"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="24 / arrows / chevron-left">
          <path d="M9.41412 12L16.707 19.2929L15.2928 20.7071L6.58569 12L15.2928 3.29291L16.707 4.70712L9.41412 12Z" />
        </g>
      </svg>
    </div>
  );
};

export default ArrowLeftIcon;
