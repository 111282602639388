import React, { useRef } from "react";
import Project from "./Project";
import Dragger from "react-physics-dragger";
import { CSSTransitionGroup } from "react-transition-group";
import ArrowLeftIcon from "../svg-icons/ArrowLeftIcon";
import ArrowRightIcon from "../svg-icons/ArrowRightIcon";
import ProjectEmpty from "./ProjectEmpty";

const ProjectList = React.forwardRef(
  ({
    onSelect,
    projects,
    selectedProjectId,
    projectHover,
    onForward,
    onBack,
    draggerRef,
    backEnabled,
    forwardEnabled,
    isTransitioning
  }) => {
    return (
      <React.Fragment>
        {backEnabled && <ArrowLeftIcon onClick={onBack} />}
        <div
          className={
            projectHover ? "projects__wrapper" : "projects__wrapper hover"
          }
        >
          <ul
            className={
              isTransitioning
                ? `project-list project-list--no-transition`
                : `project-list`
            }
            ref={draggerRef}
          >
            {projects.map((project, index) => {
              return !project.title ? (
                <ProjectEmpty key={index} />
              ) : (
                <Project
                  project={project}
                  index={index}
                  key={project.title}
                  onSelect={onSelect}
                  isSelected={
                    selectedProjectId !== undefined &&
                    selectedProjectId === project.id
                  }
                />
              );
            })}
          </ul>
        </div>
        {forwardEnabled && <ArrowRightIcon onClick={onForward} />}
      </React.Fragment>
    );
  }
);

export default ProjectList;
