import React, { useRef } from "react";
import Rectangle from "../svg-icons/Rectangle";

const Project = ({
  project,
  index,
  isSelected,
  onSelect,
  onProjectMouseOver,
  onProjectMouseLeave,
}) => {
  const projectNode = useRef();

  const handleProjectClick = () => {
    const windowScrollX = window.scrollX;
    const windowScrollY = window.scrollY;
    const projectLeft = projectNode.current.getBoundingClientRect().left;
    const projectTop = projectNode.current.getBoundingClientRect().top;
    const projectRight = projectNode.current.getBoundingClientRect().right;
    const projectBottom = projectNode.current.getBoundingClientRect().bottom;
    const left = windowScrollX + projectLeft;
    const top = windowScrollY + projectTop;
    const right = document.body.offsetWidth - projectRight;
    const bottom = document.body.offsetHeight - projectBottom - windowScrollY;
    onSelect({ left, top, bottom, right, index });
  };

  return (
    <li
      className={`${isSelected ? "project project--selected" : "project"}`}
      ref={projectNode}
      onClick={handleProjectClick}
      onMouseOver={onProjectMouseOver}
      onMouseLeave={onProjectMouseLeave}
    >
      <h3 className="project__name">{project.title}</h3>
      <p className="project__description">{project.shortDescription}</p>
      <img
        className={`${
          project.type === "mobile"
            ? "project__image"
            : "project__image project__image--web"
        }`}
        alt={project.title}
        src={project.smImage}
        draggable={false}
      />
      <Rectangle color={project.decorationColor} />
    </li>
  );
};

export default Project;
