import React from "react";

const Rectangle = ({ color }) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid"
      width="399.688"
      height="194"
      fill={color}
      viewBox="0 0 399.688 194"
      className="project-decoration"
    >
      <path
        d="M-0.008,0.000 L399.688,0.000 L399.688,194.626 L-0.008,194.626 L-0.008,0.000 Z"
        className="cls-1"
      />
    </svg>
  );
};

export default Rectangle;
