import React from "react";
import SectionHeading from "../section-heading/SectionHeading";
import ProjectList from "./ProjectList";

const Projects = React.forwardRef(
  ({
    onSelect,
    projects,
    selectedProjectId,
    projectHover,
    onForward,
    onBack,
    draggerRef,
    forwardEnabled,
    backEnabled,
    isTransitioning
  }) => {
    return (
      <React.Fragment>
        <SectionHeading>Projects</SectionHeading>
        <ProjectList
          onSelect={onSelect}
          selectedProjectId={selectedProjectId}
          projects={projects}
          projectHover={projectHover}
          onForward={onForward}
          onBack={onBack}
          draggerRef={draggerRef}
          forwardEnabled={forwardEnabled}
          backEnabled={backEnabled}
          isTransitioning={isTransitioning}
        />
      </React.Fragment>
    );
  }
);

export default Projects;
