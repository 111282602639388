import { technologies } from "../constants";

export const projectsData = [
  {
    id: "t9j5xURi",
    title: "Grab a Treat",
    shortDescription:
      "An Android app made with React Native for my dropshipping inspired business",
    description:
      "App built with React Native that allows users to get free items like: rings, bracelets, necklaces and more by completing surveys and various other tasks. It uses various advertising network APIs and SDKs to serve tasks to a custom made offer wall. Shopify API allows the app to connect to the Shopify store where all the inventory and orders are managed. Parse Server is used to store user and other data.",
    mainFeatures: [
      "Access store's inventory, sort and view product variants",
      "Add products to favorite's list",
      "Order products with points",
      "View order history",
      "GDPR Consent management",
      "Referral system",
      "Task tracking and ticket support",
    ],
    video: "https://www.youtube.com/watch?v=ajrU58zV30I",
    livePreview: "",
    playStoreUrl:
      "https://play.google.com/store/apps/details?id=com.gudekoi.grabatreat",
    appStoreUrl: "",
    sourceUrl: "https://github.com/zilkupcin/grabatreatapp",
    timeSpan: "2018",
    technologies: [
      technologies.REACT,
      technologies.CSS,
      technologies.JAVASCRIPT,
      technologies.PARSE,
      technologies.GRAPHQL,
    ],
    smImage: require("../images/grab_sm.png"),
    image: require("../images/grab_lg.jpg"),
    decorationColor: "#FDCA6E",
    type: "mobile",
  },
  {
    id: "xTaZZd4O",
    title: "Admin Dashboard",
    shortDescription:
      "An admin dashboard for the Grab a Treat app, allowing access and modification of contents of the app's database",
    description:
      "This website was built to cope with growing order and user numbers of the Grab a Treat app. It was built with React and communicates with a Parse Server to perform various operations on objects in the database. The website uses Chart.js and and jVectorMap to display statistics and demographics.",
    mainFeatures: [
      "View statistics like: daily turnover, daily user acquisition, user demographics, order turnover.",
      "Create, read, update, delete orders, users and categories.",
      "Perform custom actions like: refund orders.",
      "Modify mobile app’s configuration.",
    ],
    video: "https://www.youtube.com/watch?v=Ruiu2izAW0k",
    livePreview: "https://dashboard.zildev.com/",
    playStoreUrl: "",
    appStoreUrl: "",
    sourceUrl: "https://github.com/zilkupcin/grabatreat-admin",
    timeSpan: "2019",
    technologies: [
      technologies.REACT,
      technologies.HTML,
      technologies.CSS,
      technologies.SASS,
      technologies.JAVASCRIPT,
    ],
    smImage: require("../images/dashboard_sm.png"),
    image: require("../images/dashboard_lg.jpg"),
    decorationColor: "#FF7674",
    type: "web",
  },
  {
    id: "Ia9D2w3A",
    title: "Invoice App",
    shortDescription:
      "An invoice tracker built with Next.js, Express, MongoDB and CSS Modules",
    description:
      "This invoice tracker was built with Next.js in order to learn more about it's features. It uses an Express server that connects to MongoDB for data storage. Additionally, JWT is used for authentication. ",
    mainFeatures: [
      "Create, update and delete invoices",
      "Toggle between dark and light modes",
      "Filter invoices by status",
    ],
    video: "https://www.youtube.com/watch?v=eWIb8U6FCjg",
    livePreview: "",
    playStoreUrl: "",
    appStoreUrl: "",
    sourceUrl: "https://github.com/zilkupcin/invoice-app-client",
    timeSpan: "2021",
    technologies: [
      technologies.NEXT,
      technologies.HTML,
      technologies.CSS,
      technologies.SASS,
      technologies.JAVASCRIPT,
      technologies.MONGODB,
    ],
    smImage: require("../images/invoice_app_sm.png"),
    image: require("../images/invoice_app_lg.jpg"),
    decorationColor: "#7C5DFA",
    type: "web",
  },
  {
    id: "s8a0NFhs9",
    title: "Memory Game",
    shortDescription:
      "A multiplayer browser game that uses Firebase Firestore to bring real-time game data to each of the player's games",
    description:
      "This is a multiplayer memory game built with React which uses the Firebase Firestore Database to bring data to the front end in real time. By building this project I wanted to learn how to display data for multiple users at the same time. I was also curious about what game logic should be separated from the client in order to provide an experience where players wouldn't be able to cheat and for that I chose to use Firebase Cloud Functions.",
    mainFeatures: [
      "Single player, 2,3 and 4 player modes",
      "2 game types: icons and numbers",
      "Real-time updates of game data for all players",
      "Game's logic implemented within Firebase Cloud Functions instead of being on the client side",
    ],
    video: "https://www.youtube.com/watch?v=t6Yy9enfsIE",
    livePreview: "",
    playStoreUrl: "",
    appStoreUrl: "",
    sourceUrl: "https://github.com/zilkupcin/memory-game",
    timeSpan: "2021",
    technologies: [
      technologies.REACT,
      technologies.HTML,
      technologies.CSS,
      technologies.SASS,
      technologies.JAVASCRIPT,
    ],
    smImage: require("../images/memory_game_sm.png"),
    image: require("../images/memory_game_lg.jpg"),
    decorationColor: "#FDA214",
    type: "web",
  },

  {
    id: "I8IpDkDF",
    title: "Picky Flyer",
    shortDescription:
      "A website for people who want to experience flying on different types of aircraft",
    description:
      "Picky Flyer allows users to browse a database of flights by an aircraft of their choice. User can either look up flights by a specific route and date, flights departing from a specific airport to anywhere, or by choosing an aircraft from a list. Each of the methods returns a list of available flights and Skyscanner API is used to look up prices for these flights. If price is not found, user is given a custom built link to an alternative flight search provider. The website was built with React.",
    mainFeatures: [
      "Browse aircraft by route and date",
      "Browse flights by aircraft",
      "Flight price search",
      "Currency selection",
      "Contact form",
    ],
    video: "https://www.youtube.com/watch?v=5GUwIHITi5w",
    livePreview: "https://www.pickyflyer.zildev.com/",
    playStoreUrl: "",
    appStoreUrl: "",
    sourceUrl: "https://github.com/zilkupcin/pickyflyer",
    timeSpan: "2019",
    technologies: [
      technologies.REACT,
      technologies.CSS,
      technologies.HTML,
      technologies.JAVASCRIPT,
      technologies.PARSE,
    ],
    smImage: require("../images/picky_flyer_sm.png"),
    image: require("../images/picky_flyer_lg.jpg"),
    decorationColor: "#00B8F8",
    type: "web",
  },
  {
    id: "LQOx0R1R",
    title: "ALCOMPUTERJY",
    shortDescription:
      "An Android and iOS technology news app that uses the Wordpress API",
    description:
      "App built for a client, who wanted to make a custom app for their Wordpress news site, therefore the content is fetched using the Wordpress API. The app was built with React Native for Android and iOS, the iOS version is pending publication. AdMob ads are used to monetize the app. ",
    mainFeatures: [
      "Browse articles by category and by selected tags",
      "Receive notifications if new articles are published",
      "Two pane layout for tablets",
      "AdMob ad integration",
    ],
    video: "https://www.youtube.com/watch?v=_lXZ30ePvlU",
    livePreview: "",
    playStoreUrl:
      "https://play.google.com/store/apps/details?id=com.hajjar.alcomputerjy",
    appStoreUrl: "",
    sourceUrl: "https://github.com/zilkupcin/alcomputerjy",
    timeSpan: "2019",
    technologies: [
      technologies.REACT,
      technologies.CSS,
      technologies.JAVASCRIPT,
    ],
    smImage: require("../images/alcomputer_sm.png"),
    image: require("../images/alcomputer_lg.jpg"),
    decorationColor: "#C32025",
    type: "mobile",
  },
  {
    id: "6SrFxnrE",
    title: "Fly Anywhere",
    shortDescription:
      "An Android app that displays cheap flight deals based on user's selected tags",
    description:
      "Fly Anywhere fetches data from a Firebase Database, where cheap flight deals are stored. It uses Firebase Authentication to authenticate the users of the app, who then have the ability to subscribe to specific tags that are associated with specific flight deals. Users can also receive notifications about new deals and use a widget that displays their saved deals. This app was built in Java and was the capstone project for the 'Android Developer' Udacity course.",
    mainFeatures: [
      "Browse cheap flight deals",
      "Subscribe to deal tags",
      "Submit found deals",
      "Receive notifications about latest deals",
      "Home screen widget",
    ],
    video: "https://www.youtube.com/watch?v=F94XZO43lLo",
    livePreview: "",
    playStoreUrl: "",
    apkUrl:
      "https://drive.google.com/open?id=12R2DNdGuUs6-PQ1Y2vrsxQwHgSUklpwY",
    appStoreUrl: "",
    sourceUrl: "https://github.com/zilkupcin/fly-anywhere",
    timeSpan: "2018",
    technologies: [technologies.JAVA, technologies.FIREBASE],
    smImage: require("../images/fly_anywhere_sm.png"),
    image: require("../images/fly_anywhere_lg.jpg"),
    decorationColor: "#554588",
    type: "mobile",
  },
];
