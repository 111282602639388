import React from "react";

const ParseIcon = ({ color }) => {
  const getIcon = () => {
    switch (color) {
      case "#FF7674":
        return require("../../images/parse_logo_light_red.png");
      case "#FDCA6E":
        return require("../../images/parse_logo_yellow.png");
      case "#00B8F8":
        return require("../../images/parse_logo_blue.png");
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <img alt="parse" src={getIcon()} />
      <label className="technology__label">Parse</label>
    </React.Fragment>
  );
};

export default ParseIcon;
