import React, { useMemo, useCallback } from "react";
import SectionHeading from "../section-heading/SectionHeading";
import DefaultButton from "../buttons/DefaultButton";
import ReactIcon from "../svg-icons/ReactIcon";
import { technologies } from "../../constants";
import HtmlIcon from "../svg-icons/HtmlIcon";
import CssIcon from "../svg-icons/CssIcon";
import SassIcon from "../svg-icons/SassIcon";
import JavascriptIcon from "../svg-icons/JavascriptIcon";
import ReactPlayer from "react-player";
import ParseIcon from "../custom-images/ParseIcon";
import GraphqlIcon from "../svg-icons/GraphqlIcon";
import JavaIcon from "../svg-icons/JavaIcon";
import NextIcon from "../svg-icons/NextIcon";
import FirebaseIcon from "../svg-icons/FirebaseIcon";
import MongoDbIcon from "../svg-icons/MongoDbIcon";

const googlePlayBadge = require("../../images/google-play-badge.png");

const Project = ({ project, key }) => {
  const getIconByTechnology = (technologyId) => {
    switch (technologyId) {
      case technologies.REACT:
        return <ReactIcon key={key} color={project.decorationColor} />;
      case technologies.NEXT:
        return <NextIcon key={key} color={project.decorationColor} />;
      case technologies.MONGODB:
        return <MongoDbIcon key={key} color={project.decorationColor} />;
      case technologies.HTML:
        return <HtmlIcon key={key} color={project.decorationColor} />;
      case technologies.CSS:
        return <CssIcon key={key} color={project.decorationColor} />;
      case technologies.SASS:
        return <SassIcon key={key} color={project.decorationColor} />;
      case technologies.JAVASCRIPT:
        return <JavascriptIcon key={key} color={project.decorationColor} />;
      case technologies.PARSE:
        return <ParseIcon key={key} color={project.decorationColor} />;
      case technologies.GRAPHQL:
        return <GraphqlIcon key={key} color={project.decorationColor} />;
      case technologies.JAVA:
        return <JavaIcon key={key} color={project.decorationColor} />;
      case technologies.FIREBASE:
        return <FirebaseIcon key={key} color={project.decorationColor} />;
      default:
        break;
    }
  };

  const getButtonColorClass = () => {
    switch (project.decorationColor) {
      case "#FDCA6E":
        return "btn--yellow";
      case "#FF7674":
        return "btn--light-red";
      case "#00B8F8":
        return "btn--blue";
      case "#C32025":
        return "btn--red";
      case "#554588":
        return "btn--purple";
      case "#FDA214":
        return "btn--yellow-two";
      case "#7C5DFA":
        return "btn--purple-two";
      default:
        break;
    }
  };

  const Technologies = useCallback(() => {
    return (
      <ul className="project-details__technologies">
        {project.technologies.map((technology, index) => {
          return (
            <li className="project-details__technology" key={technology}>
              {getIconByTechnology(technology, index)}
            </li>
          );
        })}
      </ul>
    );
  }, []);

  const MainFeatures = () => {
    return (
      <ul className="project-details__features">
        {project.mainFeatures.map((feature, index) => {
          return (
            <span
              key={index}
              className="project-details__feature"
            >{`- ${feature}`}</span>
          );
        })}
      </ul>
    );
  };

  const handleLinkClick = (name) => {
    if (name === "preview") {
    } else if (name === "source") {
    }
  };

  const Links = () => {
    return (
      <div className="main-details__links">
        {project.appStoreUrl && (
          <img
            className="main-details__app-store"
            alt="app store"
            src={googlePlayBadge}
          />
        )}
        {project.livePreview && (
          <a
            href={project.livePreview}
            target="_blank"
            rel="noopener noreferrer"
          >
            <DefaultButton
              className={`btn--preview ${getButtonColorClass()}`}
              onClick={handleLinkClick}
              name="preview"
            >
              Live Preview
            </DefaultButton>
          </a>
        )}
        {project.sourceUrl && (
          <a href={project.sourceUrl} target="_blank" rel="noopener noreferrer">
            <DefaultButton
              className={`btn--source ${getButtonColorClass()}`}
              onClick={handleLinkClick}
              name="source"
            >
              View Source
            </DefaultButton>
          </a>
        )}
        {!project.livePreview && (
          <a href="#video" rel="noopener noreferrer">
            <DefaultButton
              name="video"
              onClick={handleLinkClick}
              className={`btn--source ${getButtonColorClass()}`}
            >
              Watch a Video
            </DefaultButton>
          </a>
        )}
        {project.apkUrl && (
          <a href={project.apkUrl} target="_blank" rel="noopener noreferrer">
            <DefaultButton
              className={`btn--source ${getButtonColorClass()}`}
              onClick={handleLinkClick}
              name="apk"
            >
              Download APK
            </DefaultButton>
          </a>
        )}
      </div>
    );
  };

  const MainDetailsMobile = useCallback(() => {
    return (
      <div className="main-details">
        <img className="main-details__image" src={project.image} />

        <div className="main-details__content">
          <SectionHeading>About</SectionHeading>
          <p className="main-details__about">{project.description}</p>
          <Links />
        </div>
      </div>
    );
  }, []);

  const MainDetailsWeb = useCallback(() => {
    return (
      <div className="main-details">
        <img className="main-details__image" src={project.image} />
        <Links />

        <SectionHeading>About</SectionHeading>
        <p className="main-details__about">{project.description}</p>
      </div>
    );
  }, []);

  const MainDetails = () => {
    return (
      <div className="main-details">
        <img className="main-details__image" src={project.image} />

        <div className="main-details__content">
          <SectionHeading>About</SectionHeading>
          <p className="main-details__about">{project.description}</p>
          <Links />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`project-details ${
        project.type === "mobile"
          ? "project-details--mobile"
          : "project-details--web"
      }`}
    >
      <h1 className="project-details__title" key="title">
        {project.title}
      </h1>
      {project.type === "mobile" ? <MainDetailsMobile /> : <MainDetailsWeb />}
      <SectionHeading>Main features</SectionHeading>
      <MainFeatures />
      <SectionHeading>Languages, tools and technologies</SectionHeading>
      <Technologies />
      {project.video && (
        <React.Fragment>
          <SectionHeading>Video Preview</SectionHeading>
          <div id="video" className="project-details__video">
            <ReactPlayer
              className="react-player"
              url={project.video}
              playing={false}
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Project;
