import React from "react";

const ProjectTransition = ({ projectExpanded, windowParams }) => {
  const getStyle = () => {
    if (!projectExpanded) {
      return {
        top: windowParams.top,
        left: windowParams.left,
        right: windowParams.right,
        bottom: windowParams.bottom
      };
    } else {
      return {
        top: 91 + window.scrollY
      };
    }
  };

  return (
    <div
      className={
        projectExpanded
          ? "selected-project selected-project--expanded"
          : "selected-project"
      }
      style={getStyle()}
    />
  );
};

export default ProjectTransition;
