import React from "react";

const BackIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#5a5d7a"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="24 / arrows / arrow-left">
        <path d="M6.41412 13L12.707 19.2929L11.2928 20.7071L2.58569 12L11.2928 3.29289L12.707 4.70711L6.41412 11H20.9999V13H6.41412Z" />
      </g>
    </svg>
  );
};

export default BackIcon;
