import React from "react";
import EmailIcon from "../svg-icons/EmailIcon";
import GithubIcon from "../svg-icons/GithubIcon";

const ContactList = () => {
  return (
    <ul className="contact-list">
      <li className="contact-list__item">
        <div className="contact-list__icon">
          <EmailIcon />
        </div>
        <a href="mailto:zil.kupcin@gmail.com">zil.kupcin@gmail.com</a>
      </li>
      <li className="contact-list__item">
        <div className="contact-list__icon">
          <GithubIcon />
        </div>
        <a href="https://github.com/zilkupcin?tab=repositories" target="_blank">
          github.com/zildev
        </a>
      </li>
    </ul>
  );
};

export default ContactList;
