import React from "react";

const ArrowRightIcon = ({ onClick }) => {
  return (
    <div className="arrow-forward" onClick={onClick}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="#5A5D7A"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="24 / arrows / chevron-right">
          <path d="M14.5859 12L7.29297 4.70712L8.70718 3.29291L17.4143 12L8.70718 20.7071L7.29297 19.2929L14.5859 12Z" />
        </g>
      </svg>
    </div>
  );
};

export default ArrowRightIcon;
