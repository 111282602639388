import React from "react";
import BackIcon from "../svg-icons/BackIcon";
import NavItem from "./NavItem";

const Header = ({ lifted, projectExpanded, onBackClick, onNavItemClick }) => {
  return (
    <header
      className={`${projectExpanded ? "header header--active" : "header"}`}
    >
      <div
        className={`${
          projectExpanded
            ? "header__back-icon"
            : "header__back-icon header__back-icon--hidden"
        }`}
        onClick={onBackClick}
      >
        <BackIcon />
      </div>
      <nav className="nav">
        <ul className="nav-list">
          <NavItem title="Projects" onClick={onNavItemClick} />
          <NavItem title="Contact" onClick={onNavItemClick} />
        </ul>
      </nav>
    </header>
  );
};

export default Header;
