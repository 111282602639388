import React from "react";
import SectionHeading from "../section-heading/SectionHeading";
import ContactList from "./ContactList";

const Contact = React.forwardRef(({ contactRef }) => {
  return (
    <div className="contact" id="contact" ref={contactRef}>
      <SectionHeading>Contact me</SectionHeading>
      <ContactList />
    </div>
  );
});

export default Contact;
