import React from "react";

const NavItem = ({ title, type, url, onClick }) => {
  const handleOnClick = () => {
    if (title === "Projects") {
      onClick("projects");
    } else if (title === "Contact") {
      onClick("contact");
    }
  };

  const ListItem = () => {
    return (
      <li className="nav-list__item" onClick={handleOnClick}>
        {title}
      </li>
    );
  };

  const Link = () => {
    return (
      <li className="nav-list__item">
        <a href={url} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </li>
    );
  };

  return type === "link" ? <Link /> : <ListItem />;
};

export default NavItem;
